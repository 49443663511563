import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const NotFoundPage = ({
  location,
  data: { site: { siteMetadata: { title } } },
}) => (
  <Layout location={location}>
    <Helmet title={`Page non trouvée | ${title}`} />

    <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
      <div className="row">
        <div className="col-xs-12">
          <ol className="breadcrumb">
            <li><a href="/">Home</a></li>
            {/* eslint-disable-next-line */}
            <li><a href="#">Autres pages</a></li>
            {/* eslint-disable-next-line */}
            <li className="active"><a href="#">404</a></li>
          </ol>
        </div>
      </div>
    </div>

    <div className="container-fluid horizontal-section-container clearfix">
      <div className="row">

        <div className="col-sm-12">

          <div id="page-not-found-content" className="section-container main-page-content clearfix">

            <div className="section-content">

              <div className="page-not-found-container">
                <div className="content-404"><i className="fa fa-warning"></i> 404</div>
                <div className="content-text">
                  <p>Page non trouvée !</p>
                  <p><Link to="/" className="btn btn-outline-inverse btn-md">Accueil</Link></p>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>
)

export default NotFoundPage

export const notFoundPageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`